<template>
  <qtm-dialog-card
    v-if="emailContent"
    v-model="show"
    scrollable
    width="1200"
  >
    <template v-slot:title>
      {{ emailContent.subject || 'No subject' }}
      <div class="dialog-buttons">
        <qtm-btn
          v-if="emailContent.rfq"
          @click="showOrder(emailContent)"
        >
          <v-icon>
            mdi-open-in-new
          </v-icon>
          View Order
        </qtm-btn>

        <v-chip
          v-if="emailContent.is_general_inbox"
          color="cyan"
          label
          text-color="white"
          variant="flat"
        >
          General inbox
        </v-chip>

        <qtm-icon-btn :icon="pinIcon" :title="isEmailPinned ? 'Unpin' : 'Pin'" @click="pinEmail" />

        <qtm-icon-btn icon="mdi-close" color="interactive" title="Close" @click="closeEmail" />
      </div>
    </template>
    <v-divider />
    <view-email-detail
      v-model:email-content="emailContent"
      :can-create-invoice="order && order.state.can_create_invoice"
      class="email-dialog"
      @create-invoice="prepCreateInvoice"
      @reply="showReplyDialog"
      @refresh="$emit('refresh')"
    />

    <send-email
      v-if="showSendEmailDialog"
      :company-id="inReplyTo?inReplyTo.company:null"
      :replied-email="inReplyTo"
      :reply-type="replyType"
      :order="(inReplyTo && inReplyTo.rfq)?{ id:inReplyTo.rfq }:null"
      :show="showSendEmailDialog"
      @close="hideSendingDialog"
    />

    <invoice-create-dialog v-if="order" v-model="showCreateInvoice" :email="createInvoiceEmail" :order="order" />
  </qtm-dialog-card>
</template>

<script>
import { mapActions } from 'pinia'
import InvoiceCreateDialog from '@/components/invoices/invoice-create-dialog.vue'
import ViewEmailDetail from '@/components/admin/emails/view-email-detail.vue'
import SendEmail from '@/components/admin/emails/send-email.vue'
import { usePins } from '@/composables/pins'

export default {
  name: 'dialog-view-email-detail',
  components: { InvoiceCreateDialog, ViewEmailDetail, SendEmail },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    modelValueOnly: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value', 'refresh'],
  setup() {
    const adminStore = useAdminStore()
    const activeEmail = computed(() => adminStore.activeEmail)

    const { addPin, isPinned, removePin } = usePins()

    return {
      addPin, activeEmail, isPinned, removePin
    }
  },
  data() {
    return {
      createInvoiceEmail: null,
      inReplyTo: null,
      order: null,
      replyType: 'single',
      showCreateInvoice: false,
      showSendEmailDialog: false,
    }
  },
  computed: {
    emailContent: {
      get() { return this.activeEmail || this.modelValue },
      set(value) {
        if (this.activeEmail) {
          this.activateEmail(value || null)
        }
        else {
          this.$emit('update:model-value', value)
        }
      }
    },
    isEmailPinned() {
      return this.isPinned(this.emailContent?.id, 'email')
    },

    pinIcon() {
      return this.isEmailPinned ? 'mdi-pin-off' : 'mdi-pin'
    },
    show: {
      get() {
        const show = (this.activeEmail && !this.modelValueOnly) || this.modelValue
        return !!show
      },
      set() { this.emailContent = null }
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newEmail, oldEmail) {
        if (this.$route.query.activeEmail?.toString() !== newEmail?.id?.toString() && newEmail?.id !== oldEmail?.id) {
          this.$router.replace({
            ...this.$route,
            query: {
              activeEmail: newEmail?.id,
            }
          })
        }
      },
    },
    activeEmail: {
      immediate: true,
      async handler(newEmail, oldEmail) {
        if (!newEmail) {
          return
        }

        if (this.$route.query.activeEmail?.toString() !== newEmail?.id?.toString() && newEmail?.id !== oldEmail?.id) {
          this.$router.replace({
            ...this.$route,
            query: {
              activeEmail: this.activeEmail?.id,
            }
          })
        }

        if (oldEmail && newEmail.id === oldEmail.id) {
          return
        }
        try {
          const activeEmail = await this.$api.v1.notifications.email.detail(newEmail.id)
          if (activeEmail.rfq) {
            this.order = await this.$api.v1.rfqs.get(activeEmail.rfq)
          }
          this.activateEmail(activeEmail)
        }
        catch (error) {
          this.$error.report(error)
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.activeEmail) {
      this.activateEmail({ id: parseInt(this.$route.query.activeEmail, 10) })
    }
  },
  methods: {
    ...mapActions(useAdminStore, ['activateEmail', 'activateOrder']),
    closeEmail() {
      this.emailContent = null
    },
    hideSendingDialog() {
      this.inReplyTo = null
      this.showSendEmailDialog = false
    },
    pinEmail() {
      if (this.isEmailPinned) {
        this.removePin(this.emailContent.id, 'email')
      }
      else {
        this.addPin({
          title: this.emailContent.subject,
          type: 'email',
          objectId: this.emailContent.id,
        })
      }
    },
    prepCreateInvoice(email) {
      this.createInvoiceEmail = email
      this.showCreateInvoice = true
    },
    showOrder(email) {
      this.activateOrder({ id: email.rfq })
    },
    showReplyDialog(email, type) {
      this.inReplyTo = email
      this.replyType = type
      this.showSendEmailDialog = true
    },
  }
}
</script>

<style lang="scss" scoped>
  .dialog-buttons {
    position: absolute;
    top: 0.7rem;
    right: 0.5rem;
  }
  .email-dialog {
    margin: 0 -24px -20px -24px;
    padding: 12px;
  }
</style>
<style lang="scss">
  .email-dialog .email-data {
    background-color: white;
    color: black;
    padding-top: 10px;
  }
</style>
