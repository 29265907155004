<template>
  <qtm-dialog-card
    v-bind="$attrs"
    closable
    :title="title"
    width="800px"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <qtm-input-label label="Supplier" required>
      <qtm-select
        v-model="quote"
        :item-title="quoteText"
        :items="quotes"
        return-object
      />
    </qtm-input-label>
    <qtm-input-label class="mb-4 pt-2" label="Supplier Contact" :required="!contact">
      <div class="d-flex justify-space-between">
        <div v-if="contact">
          <div v-text="`${contact.first_name} ${contact.last_name}`" />
          <div v-text="contact.email" />
        </div>
        <qtm-btn :disabled="!quote" :loading="loading" prepend-icon="mdi-pencil" secondary @click="openChangeContact">
          Change Contact
        </qtm-btn>
      </div>
      <qtm-dialog-card v-model="changeContactDialog" title="Change Supplier Contact">
        <qtm-input-label label="Supplier Contact" required>
          <qtm-select
            v-model="otherContact"
            :items="contacts"
            :item-title="contactText"
            return-object
          />
        </qtm-input-label>
        <template v-slot:actions>
          <v-spacer />
          <qtm-btn :loading="loading" size="x-large" tertiary @click="changeContactDialog = false">
            Cancel
          </qtm-btn>
          <qtm-btn :loading="loading" size="x-large" @click="changeContact">
            Save
          </qtm-btn>
        </template>
      </qtm-dialog-card>
    </qtm-input-label>
    <qtm-textarea
      v-model="text"
      class="chat-input"
      autofocus
      hide-details
      :loading="loading"
    />
    <qtm-input-label v-if="email.attachments.length" class="mt-4" label="Select Attachments">
      <p class="text-caption">
        The selected attachments will be duplicated to the comment.
      </p>
      <order-attachments
        v-model:selected-attachments="attachments"
        allow-select
        class="bg-background"
        :model-value="email.attachments"
        no-upload
        no-delete
        remove-title
      />
    </qtm-input-label>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn class="mr-2" :loading="loading" size="x-large" tertiary @click="$emit('update:model-value', false)">
        Cancel
      </qtm-btn>
      <qtm-btn :disabled="disabled" :loading="loading" size="x-large" @click="save">
        Save to Chat
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import type { Contact, Quote } from '@quotetome/materials-api'
import OrderAttachments from '@/components/orders/order-attachments.vue'

export interface Props {
  email: any
  quotes?: Quote[]
}

const props = withDefaults(defineProps<Props>(), {
  quotes: () => [],
})
const emit = defineEmits(['update:model-value'])

const attachments = ref([])
const changeContactDialog = ref(false)
const contact = ref()
const contacts = ref([])
const loading = ref(false)
const otherContact = ref()
const quote = ref()
const text = ref('')

const disabled = computed(() => !quote.value || !contact.value)
const title = computed(() => {
  const base = 'Add to Chat'

  if (quote.value) {
    return `${base} - ${quoteText(quote.value)}`
  }

  return base
})

const contactText = (c: Contact) => `${c.first_name} ${c.last_name} (${c.email})`
const quoteText = (q: Quote) => {
  return `${q.salesperson?.first_name} ${q.salesperson?.last_name} - ${q.vendor?.name}`
}

const { $api, $error, $toast } = useNuxtApp()

onMounted(async () => {
  quote.value = props.quotes.find(q => q.salesperson?.email === props.email.from_address)
  loading.value = true

  try {
    text.value = await $api.v1.notifications.email.text(props.email.id)
  }
  catch (error) {
    $error.report(error)
  }

  loading.value = false
})

const changeContact = () => {
  contact.value = otherContact.value
  changeContactDialog.value = false
}

const fetchContacts = async () => {
  if (!quote.value) {
    contacts.value = []
    return
  }

  loading.value = true
  try {
    contacts.value = await $api.v1.vendors.contacts(quote.value.vendor.id)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const openChangeContact = () => {
  changeContactDialog.value = true
  otherContact.value = contact.value
}

const save = async () => {
  loading.value = true
  try {
    await $api.v1.quotes.chat.create(quote.value.id, {
      attachments_to_copy: attachments.value,
      created_by: contact.value!.id,
      email: props.email.id,
      text: text.value,
    })

    $toast.success('Saved to chat')
    emit('update:model-value', false)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

watch(quote, () => {
  contact.value = quote.value?.salesperson ?? null
  fetchContacts()
})
</script>

<style lang="scss">
.chat-input textarea {
  height: 40vh;
}
</style>
